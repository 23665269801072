
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "SuccessTile",
  props: {
    id: { type: String, required: true },
    canClose: { type: Boolean, default: true },
    text: { type: String, default: "" },
    autoClose: { type: Boolean, default: true },
  },
  setup(props, context) {
    const store = useStore();
    const FADE_OUT_TIME = 5000;
    let timer: any;
    onMounted(() => {
      if (props.autoClose) {
        timer = setTimeout(function () {
          store.commit("notification/enabledAnimation", true);
          store.commit("notification/removeAllNotification");
        }, FADE_OUT_TIME);
      }
    });

    onUnmounted(() => {
      clearTimeout(timer);
    })

    const close = () => {
      if (props.autoClose) {
        clearTimeout(timer);
        store.commit("notification/enabledAnimation", true);
        store.commit("notification/removeAllNotification");
      } else {
        context.emit("onClose");
      }
    };
    return {
      close,
    };
  },
});
