import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-909ea61e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "success-wrapper" }
const _hoisted_2 = { class: "success" }
const _hoisted_3 = { class: "success-leading" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ml-1" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('icons/success-check.svg'),
          alt: "success"
        }, null, 8, _hoisted_4),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.text), 1)
      ]),
      _withDirectives(_createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"])),
        src: require('icons/close-white.svg'),
        alt: "icon close"
      }, null, 8, _hoisted_6), [
        [_vShow, _ctx.canClose]
      ])
    ])
  ]))
}