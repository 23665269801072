
import { defineComponent } from "vue";
import TwoFactorQR from "@/components/organisms/TwoFactorQR.vue";
import { AuthRepository, RepositoryFactory } from "@/lib/https";
import { FormContext } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { storage, key } from "@/lib/storage";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TwoFactorRequireLogin",
  components: {
    TwoFactorQR,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    totpSecret: {
      type: String,
      required: true,
    },
    qrCode: {
      type: String,
      required: true,
    },
    afterLoginData: { type: String, default: "" },
    isContractLogin: { type: String, default: "false" },
    requireAccessCode: { type: String, default: "false" },
  },

  setup(props) {
    const router = useRouter();

    const otherFormValues = {
      email: props.email,
      password: props.password,
    };

    const verifyCode = async function (form: FormContext) {
      const { loginByEmail } =
        RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

      const { formHandle } = useFormHandler(form, loginByEmail);

      const response = await formHandle();
      if (response) {
        const localStorage = storage.getLocalStorage();
        localStorage.add(key.sessionId, response.sessionId);
        if (props.isContractLogin == "true") {
          const localStorage = storage.getLocalStorage();
          const guestCode = localStorage.get("guestCode");
          const decode = Buffer.from(guestCode, "base64").toString();

          const details = JSON.parse(decode);
          localStorage.add("userType", response.type);

          if (props.requireAccessCode === "true")
            router.push({
              name: "Access Code",
            });
          else router.push(`/sign/details?contractId=${details.contractId}`);
        } else {
          if (props.afterLoginData) {
            const afterLoginData = JSON.parse(props.afterLoginData);
            router.push({
              name: afterLoginData.name,
              query: afterLoginData.query,
              params: afterLoginData.params,
            });
          } else {
            router.push({ name: "Dashboard" });
          }
        }
      }
    };

    return {
      verifyCode,
      otherFormValues,
    };
  },
});
