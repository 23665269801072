import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e76c03fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "two-factor-page" }
const _hoisted_2 = { class: "white-container" }
const _hoisted_3 = { class: "header mb-5" }
const _hoisted_4 = { class: "tagline" }
const _hoisted_5 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoFactorQR = _resolveComponent("TwoFactorQR")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("login.twoFactorTagline")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("login.twoFactorTitle")), 1)
      ]),
      _createVNode(_component_TwoFactorQR, {
        secret: _ctx.totpSecret,
        "qr-image": _ctx.qrCode,
        "verify-method": _ctx.verifyCode,
        "other-form-values": _ctx.otherFormValues
      }, null, 8, ["secret", "qr-image", "verify-method", "other-form-values"])
    ])
  ]))
}